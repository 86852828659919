import { Carousel, EntityCard, HeadingTag, Link, MediaLightboxCarousel, ModuleBase, Stack, Text } from '@/components';
// import { useDimensions } from '@/hooks';
import { fixColor, isColorDark } from '@/utils';
import classNames from 'classnames';
import Arrow from '@/assets/carouselArrow.svg';
import React, { useEffect, useState } from 'react';
import { isIOS, isMacOs, isSafari } from 'react-device-detect';
import AnimationStarter from '@/animations/animationComponent/AnimationStarter';
import { useTranslation } from 'next-i18next';
import CarouselIndicator from '@/assets/carouselIndicator.svg';

const CardCarouselGenericModule = ({ data }) => {
  const { cardRow } = data || {};
  const { t } = useTranslation('common');
  // const { breakpoint } = useDimensions();
  const isDark = isColorDark(fixColor(data.backgroundColour));
  const [activeImage, setActiveImage] = useState(null);
  const [paginationClass, setPaginationClass] = useState();
  const lightboxImages = cardRow
    .map((row) =>
      row.props?.cards
        .map((card) => {
          if (card.props?.mediaLightBox && card.props?.mediaLightBox?.length > 0) {
            return [...card.props.mediaLightBox];
          }
        })
        .flat()
        .filter((item) => item !== undefined),
    )
    .flat();

  useEffect(() => {
    if (isMacOs || isIOS || isSafari) {
      setPaginationClass('!w-screen landscape:!w-screen');
    } else {
      setPaginationClass('!w-[calc(100vw-17px)]');
    }
  }, []);

  const getItems = (items) => {
    return items.map((item, index) => {
      return <EntityCard data={item} key={index} className="!w-full [&_picture]:pointer-events-none" />;
    });
  };

  return (
    <ModuleBase data={data} className="overflow-hidden">
      {data.showLightboxCta && lightboxImages.length > 0 && activeImage !== null && (
        <MediaLightboxCarousel
          slides={lightboxImages}
          moduleId={data.moduleId}
          activeImage={activeImage}
          onClose={() => setActiveImage(null)}
        />
      )}

      {(data.headingTitle.heading.length > 0 || data.description || data.primaryCta || data.secondaryCta) && (
        <Stack
          role="contentinfo"
          aria-label="contentinfo"
          className="container mb-10 lg:flex lg:items-end lg:justify-between"
        >
          {(data.headingTitle || data.description) && (
            <Stack>
              {data.headingTitle && (
                <HeadingTag data={data.headingTitle} className="color-from-bg title-04 lg:max-w-[456px]" />
              )}
              {data.description && (
                <Text
                  dangerouslySetInnerHTML={{ __html: data.description }}
                  className="color-from-bg body-03 mb-05 mt-4 !text-opacity-75 lg:mb-0 lg:max-w-[456px]"
                />
              )}
            </Stack>
          )}
          {(data.primaryCta || data.secondaryCta) && (
            <Stack className="flex flex-col gap-02 md:flex-row">
              <Link animate className={classNames('btn primary', isDark ? 'dark' : 'light')} link={data.primaryCta} />
              <Link
                animate
                className={classNames('btn secondary', isDark ? 'dark' : 'light')}
                link={data.secondaryCta}
              />
            </Stack>
          )}
        </Stack>
      )}
      <AnimationStarter className="relative">
        {cardRow.map(({ props: row }, rIndex) => (
          <div key={rIndex} className="container">
            <Carousel
              items={getItems(row.cards)}
              animationStyle="elegant"
              gap={8}
              controls={{
                show: true,
                directionComponent: <Arrow />,
                controlStyle: {
                  prev: 'pagination charcoal left pointer-events-auto rtl:[&_svg]:rotate-180',
                  next: 'pagination charcoal right pointer-events-auto [&_svg]:rotate-180 rtl:[&_svg]:rotate-0',
                  hide: 'opacity-0 !pointer-events-none',
                },
                wrapperStyle: `pointer-events-none ${paginationClass} p-0 hidden left-0  lg:flex justify-between absolute top-1/2 -translate-y-1/2`,
              }}
              // px-4 sm:px-6 lg:px-10
              width={{ base: 343, md: 348, lg: 472 }}
              loop
              className="[&_.card-wrapper]:w-full [&_.dragging]:pointer-events-none"
            />
          </div>
        ))}

        {data.showLightboxCta && lightboxImages.length > 0 && (
          <div className="container mt-05 lg:mt-10">
            <button
              className={classNames(
                'btn secondary [&_svg]:!fill-none [&_svg_path]:stroke-current',
                isDark ? 'dark' : 'light',
              )}
              onClick={() => setActiveImage(0)}
            >
              <CarouselIndicator role="presentation" className="scale-125" />
              {t('general.$viewAllImages')}
            </button>
          </div>
        )}
      </AnimationStarter>
    </ModuleBase>
  );
};
export default CardCarouselGenericModule;
