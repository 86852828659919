import React, { useState } from 'react';
import { FormControl, Link } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { AnimatePresence, motion } from 'framer-motion';

const MediaAssetsLibraryLoggedInModule = ({ data }) => {
  const isDark = isColorDark(data.backgroundColour);
  const { t } = useTranslation('common');
  const [selectedHotels, setSelectedHotels] = useState({});

  const brandAssets = data.assets
    .map((group) => ({
      title: group.title,
      assets: group.assets.filter((item) => item.name === 'Brand'),
    }))
    .filter((group) => group.assets.length > 0);

  const propertyAssets = (data.assets || [])
    .map((item) => ({
      ...item,
      assets: (item.assets || []).filter((asset) => asset.name !== 'Brand'),
    }))
    .filter((item) => item.assets.length > 0);

  const handleHotelChange = (groupId) => (event) => {
    setSelectedHotels((prevState) => ({
      ...prevState,
      [groupId]: event.target.value,
    }));
  };

  return (
    <>
      <motion.div layout>
        <div className="asset-group text-left last:mb-0 md:mb-16">
          <div className="mb-5 hidden justify-between px-4 md:flex md:px-6 lg:px-10">
            <div className="color-from-bg body-03 font-semibold">{t('assetLibrary.$name')}</div>
            <div className="color-from-bg body-03 font-semibold md:mr-[39px] lg:mr-[136px] lg:w-[145px]">
              {t('assetLibrary.$size')}
            </div>
          </div>

          {brandAssets?.map((assetsItems, assetsItemIndex) => (
            <>
              <p className="title-04 mx-4 mb-4 mt-[60px] md:mx-6 md:mb-7 lg:mx-10 lg:mb-12">{assetsItems?.title}</p>
              {assetsItems.assets.map((asset, assetIndex) => (
                <div
                  key={assetsItemIndex}
                  className={classNames(
                    'flex flex-col justify-between border-y px-4 py-4 md:flex-row md:items-center md:px-6 md:py-5 lg:px-10 lg:py-6',
                    isDark ? 'border-y-silk/25' : 'border-y-charcoal/25',
                    assetIndex === 0 ? 'border-b-0' : 'border-b',
                  )}
                >
                  <div key={assetIndex} className="w-full md:flex md:items-center md:justify-between">
                    <div className="asset-name flex justify-between">
                      <div className="name body-02 mb-3 font-semibold md:mb-0">{asset?.asset?.name}</div>
                      <div className="size body-02 md:hidden">{asset?.asset?.size}</div>
                    </div>

                    <div className="detail-wrapper flex flex-col md:flex-row md:items-center md:gap-x-10">
                      <div className="size body-02 hidden md:flex lg:min-w-[145px]">{asset?.asset?.size}</div>
                      {asset?.asset?.url && (
                        <div className="view md:flex">
                          <Link
                            link={{ href: asset?.asset?.url, text: t('general.$view') }}
                            className={classNames(isDark ? 'dark' : 'light', 'btn secondary')}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ))}
        </div>

        <div className="asset-group text-left">
          <AnimatePresence>
            {propertyAssets.map((group, groupIndex) => {
              const selectedHotel = selectedHotels[groupIndex] || t('assetLibrary.$allProperty');

              const filteredAssets =
                selectedHotel && selectedHotel !== t('assetLibrary.$allProperty')
                  ? group.assets.filter((asset) => asset.name === selectedHotel)
                  : group.assets;

              return (
                <div key={groupIndex}>
                  <p className="title-04 mx-4 mb-4 mt-[60px] md:mx-6 md:mb-7 lg:mx-10 lg:mb-8">{group.title}</p>
                  {/* Dropdown Filter for Each Group */}
                  <div className="w-[308px] px-4 md:pl-6 lg:pl-10">
                    <FormControl
                      aria-label={t('assetLibrary.$allProperty')}
                      type="select"
                      required
                      name={`assets-${groupIndex}`}
                      value={selectedHotel}
                      options={[
                        { label: t('assetLibrary.$allProperty'), value: t('assetLibrary.$allProperty') },
                        ...Array.from(
                          new Set(group.assets.map((asset) => asset.name)), // Unique names
                        ).map((uniqueName) => ({
                          label: uniqueName,
                          value: uniqueName,
                        })),
                      ]}
                      onChange={handleHotelChange(groupIndex)}
                      className={classNames(
                        '[&_.content]:text-charcoal [&_.main-button]:!bg-sandstone/0 [&_.pop-over]:z-10 [&_.popover-title]:!font-medium [&_li]:font-medium',
                        isDark
                          ? '[&_.main-button]:!border-silk/50 [&_.popover-title]:!text-silk'
                          : '[&_.main-button]:!border-charcoal/50 [&_.popover-title]:!text-charcoal',
                      )}
                    />
                  </div>

                  <div className="body-03 color-from-bg mx-4 mb-5 mt-9 font-semibold opacity-75 md:mx-6 md:mb-8 lg:mx-10">
                    {t('general.$showingResults', {
                      showing: filteredAssets?.length,
                      results: group?.assets?.length,
                    })}
                  </div>

                  <div className="mb-5 hidden justify-between px-4 md:flex md:px-6 lg:px-10">
                    <div className="color-from-bg body-03 font-semibold md:w-[250px] lg:w-[450px]">
                      {t('assetLibrary.$name')}
                    </div>
                    <div className="color-from-bg body-03 font-semibold md:w-[320px] lg:w-[320px]">
                      {t('assetLibrary.$propertyName')}
                    </div>
                    <div className="color-from-bg body-03 font-semibold md:mr-[39px] lg:mr-[136px] lg:w-[145px]">
                      {t('assetLibrary.$size')}
                    </div>
                  </div>

                  {/* Filtered Assets for Each Group */}
                  {filteredAssets.map((asset, assetIndex) => (
                    <motion.div
                      key={assetIndex}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ type: 'tween', duration: 0.8 }}
                      className={classNames(
                        'flex flex-col justify-between border-y px-4 py-4 md:flex-row md:items-center md:px-6 md:py-5 lg:px-10 lg:py-6',
                        isDark ? 'border-y-silk/25' : 'border-y-charcoal/25',
                        assetIndex === 0 ? 'border-b' : 'border-t-0',
                      )}
                    >
                      <div className="asset-name flex justify-between md:w-[250px] lg:w-[450px]">
                        <div className="name body-02 mb-3 font-semibold md:mb-0">{asset?.asset?.name}</div>

                        <div className="size body-02 md:hidden">{asset?.asset?.size}</div>
                      </div>
                      <p class="body-02 mb-3 hidden md:mb-0 md:flex md:w-[250px] lg:w-[325px]">{asset?.name}</p>

                      <div className="detail-wrapper flex flex-col md:flex-row md:items-center md:gap-x-10">
                        <div className="size body-02 hidden md:flex lg:min-w-[145px]">{asset?.asset?.size}</div>
                        {asset?.asset?.url && (
                          <div className="view md:flex">
                            <Link
                              link={{ href: asset?.asset?.url, text: t('general.$view') }}
                              className={classNames(isDark ? 'dark' : 'light', 'btn secondary')}
                            />
                          </div>
                        )}
                      </div>
                    </motion.div>
                  ))}
                </div>
              );
            })}
          </AnimatePresence>
        </div>
      </motion.div>
    </>
  );
};

export default MediaAssetsLibraryLoggedInModule;
