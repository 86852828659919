import React, { useEffect, useState } from 'react';
import { ModuleBase, EntityCard, Link, HeadingTag, Stack, Text, Carousel } from '@/components';
import Arrow from '../assets/carouselArrow.svg';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
import { useDimensions } from '@/hooks';
import { isIOS, isMacOs, isSafari } from 'react-device-detect';
import { animControllerInView } from '@/animations';
import AnimationStarter from '@/animations/animationComponent/AnimationStarter';

const CardCarouselFocusModule = ({ data }) => {
  const { cardRow, headingTitle, description } = data || {};
  const { breakpoint } = useDimensions();
  const isDark = isColorDark(data?.backgroundColour);
  const [paginationClass, setPaginationClass] = useState();

  useEffect(() => {
    if (isMacOs || isIOS || isSafari) {
      setPaginationClass('!w-screen landscape:!w-screen');
    } else {
      setPaginationClass('!w-[calc(100vw-17px)]');
    }
  }, []);

  const getItems = (row) => {
    return row.props.cards.map((card, index) => (
      <EntityCard
        key={`card-${index}`}
        large
        data={card}
        className="lg:h-full [&_.image-wrapper]:cursor-grab [&_picture]:pointer-events-none"
      />
    ));
  };

  return (
    <ModuleBase data={data} className="overflow-hidden">
      <div className="flex flex-col items-center gap-05 lg:gap-10">
        <Stack
          {...animControllerInView({ amount: 0.2 })}
          className="container flex w-full flex-col justify-between gap-6 lg:flex-row lg:items-end lg:gap-10"
        >
          <div className="flex flex-col gap-05 lg:w-[456px] lg:gap-[18px]">
            {headingTitle && <HeadingTag animate data={headingTitle} className="title-04 color-from-bg" />}

            {description && (
              <Text
                dangerouslySetInnerHTML={{ __html: description }}
                className="body-03 color-from-bg !text-opacity-75"
              />
            )}
          </div>
          <div className="flex flex-col gap-02 sm:flex-row">
            <Link animate className={classNames('btn primary', isDark && 'dark')} link={data?.primaryCta} />
            <Link animate className={classNames('btn secondary', isDark && 'dark')} link={data?.secondaryCta} />
          </div>
        </Stack>
        <AnimationStarter className="relative w-full overflow-hidden px-4 md:px-6 lg:px-[148px]">
          {cardRow?.map((row, rIndex) => (
            <div key={rIndex} className="">
              <Carousel
                moduleId={data.moduleId}
                items={getItems(row)}
                animationStyle="elegant"
                gap={8}
                loop
                controls={{
                  show: true,
                  directionComponent: <Arrow />,
                  controlStyle: {
                    prev: 'pagination charcoal left pointer-events-auto rtl:[&_svg]:rotate-180',
                    next: 'pagination charcoal right pointer-events-auto [&_svg]:rotate-180 rtl:[&_svg]:rotate-0',
                  },
                  wrapperStyle: `pointer-events-none hidden lg:flex justify-between absolute ${paginationClass}  px-0 left-0 top-1/2 -translate-y-1/2`,
                }}
                className="[&_.carousel-item]:h-full lg:[&_.carousel-item]:aspect-[11/5] lg:[&_.carousel-item]:h-auto [&_.carousel-wrapper]:items-center"
                variant={['lg', 'xl', 'xxl'].includes(breakpoint) ? 'primary' : 'none'}
              />
            </div>
          ))}
        </AnimationStarter>
      </div>
    </ModuleBase>
  );
};
export default CardCarouselFocusModule;
